<template>
  <div class="h-100">
    <div v-if="!appointedToHost">
      <b-overlay
        :show="isLoading || isLoadingAppointed"
        rounded="sm"
      >
        <div class="d-flex">
          <div v-if="showLeftPanel && !isMobile" style="width: 15em;">
            <b-card class="h-100 left-menu" style="min-height: 70vh;">
              <div class="d-flex flex-wrap justify-content-between align-items-between h-100">
                <!-- Filters -->
                <b-list-group class="list-group-messages w-100">
                  <b-list-group-item
                    v-for="filter in issueFilters"
                    :key="filter.title"
                    active-class="bg-light-primary"
                    :active="currentLeftMenuFilter === filter.title.toLowerCase()"
                    @click="getFilteredIssue(filter)"
                  >
                    <feather-icon
                      :icon="filter.icon"
                      size="18"
                      class="mr-75"
                    />
                    <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                    <b-badge
                      v-if="!$isEmpty(issuesMeta) ? issuesMeta[filter.field][filter.value !== '' ? filter.value : 'all' ] : 'all'"
                      pill
                      :variant="`primary`"
                      class="float-right"
                    >
                      {{ !$isEmpty(issuesMeta) ? issuesMeta[filter.field][filter.value !== '' ? filter.value : 'all' ] : 'all' }}
                    </b-badge>
                  </b-list-group-item>
                </b-list-group>

                <!-- <div class="h-25 mt-auto">
                  <next-queue-number /> 
                </div> -->
              </div>
            </b-card>
          </div>

          <div class="w-100 ml-1">
            <b-card class="right-pane px-1">
              <div class="d-flex flex-wrap justify-content-between my-0 my-xl-2">
                <!-- ITEMS PER PAGE -->
                <b-row
                  class="m-0 p-0 mt-1 mt-md-0"
                  :class="{'w-100': isMobile}"
                >
                  <ag-grid-select-page-size
                    :class="{'w-100': isMobile}"
                    :pagination="pagination"
                    @per-page-changed="pagination.perPage = $event"
                  />
                </b-row>

                <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                <!-- ACTION - DROPDOWN -->
                <b-row class="">
                  <!-- <b-col>
                    <b-button
                      variant="primary"
                      class=""
                      style="height: 40px;min-width: 250px;"
                      @click="showLeftPanel = !showLeftPanel"
                    >Toggle Left Panel</b-button>
                  </b-col> -->
                  <b-col
                    col
                    cols="12"
                    md="auto"
                    class="mt-1 mt-md-0"
                  >
                    <b-dropdown
                      :style="`height: 40px; ${ isMobile ? 'width: 100%' : (isTablet ? 'min-width: 150px' : 'min-width: 180px')}`"
                      split
                      :text="action.text"
                      right
                      :variant="action.variant"
                      :icon="action.icon"
                      type="filled"
                      class="cursor-pointer"
                      @click="performAction"
                    >
                      <b-dropdown-item @click="changeAction('Actions')">
                        <span class="d-flex justify-content-start">
                          <feather-icon
                            icon="InfoIcon"
                            svg-classes="h-4 w-4"
                            class="mr-1"
                          />
                          <span class="w-32">Actions</span>
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="changeAction('Add Ticket')">
                        <span class="d-flex justify-content-start">
                          <feather-icon
                            icon="PlusIcon"
                            svg-classes="h-4 w-4"
                            class="mr-1"
                          />
                          <span class="w-32">Add Ticket</span>
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="!$store.state.auth.ActiveUser.email_address.includes('@headhuntershq.com.sg')"
                        @click="changeAction('Appoint to Host')"
                      >
                        <span class="d-flex justify-content-start">
                          <feather-icon
                            icon="UserIcon"
                            svg-classes="h-4 w-4"
                            class="mr-1"
                          />
                          <span class="w-32">Appoint to Host</span>
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                  <b-col
                    col
                    cols="12"
                    md="auto"
                    class="mt-1 mt-md-0"
                  >
                    <b-button
                      :style="`height: 40px; ${ isMobile ? 'width: 100%' : (isTablet ? 'min-width: 150px' : 'min-width: 150px')}`"
                      variant="primary"
                      class=""
                      @click="exportToCSV"
                    >Export as CSV</b-button>
                  </b-col>
                </b-row>
              </div>

              <!-- AgGrid Table -->
              <div style="min-width: 35vw;min-height: 10vh;width: 100%; height: 100%;">
                <!-- <ag-grid-vue
                  v-if="issuesData.length > 0"
                  ref="agGridTable"
                  :context="context"
                  :grid-options="gridOptions"
                  style="width: 100%; height:65vh;"
                  class="ag-theme-material"
                  :column-defs="columnDefs"
                  :default-col-def="defaultColDef"
                  :row-data="issuesData"
                  row-selection="multiple"
                  col-resize-default="shift"
                  :animate-rows="true"
                  :pagination="true"
                  :pagination-page-size="pagination.perPage"
                  :suppress-pagination-panel="true"
                  @cellValueChanged="onCellChange"
                  @sortChanged="onSortChanged"
                  @filterChanged="onFilterChanged"
                  @grid-ready="onGridReady"
                />
                <ag-grid-no-data v-else :grid-options="gridOptions" :column-defs="columnDefs" /> -->

                <b-row class="mt-1">
                  <b-col>
                    <div class="d-flex h-100 align-items-center">
                      <div class="d-flex">
                        <div class="bg-danger" style="height: 1em; width: 1em;"></div>
                        <div class="ml-1">Urgent </div>
                      </div>
                      <div class="d-flex ml-3">
                        <div class="bg-info" style="height: 1em; width: 1em;"></div>
                        <div class="ml-1">New </div>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <b-pagination
                      v-model="pagination.currentPage"
                      class="my-1 float-right mr-2"
                      :total-rows="pagination.totalRows"
                      :per-page="pagination.perPage"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </div>
      </b-overlay>
    </div>

    <div
      v-else
      class="d-flex flex-wrap align-items-center text-center"
      style="min-height: 75vh;"
    >
      <div class="w-100">
        <div>
          <h1>
            Help Desk is currently managed by HeadHunters HQ
          </h1>
        </div>
        <div
          class="mt-2"
        >
          <b-button
            variant="success"
            @click="confirmUnappointToHost"
          >
            Take Back Control
          </b-button>
        </div>
      </div>
    </div>

    <b-modal
      v-model="popupIssueForm"
      size="lg"
      centered
      hide-footer
      :title="`Create New Ticket`"
    >
      <help-desks-issue-form 
        :selected-issue-id="selectedIssueId"
        @close-popup="closeHelpDesksIssueForm"
      />
    </b-modal>
  </div>
</template>

<script>
import AgGridSelectPageSize from '@/components/AgGridSelectPageSize.vue'

import HelpDesksIssueFloating from '@/components/HelpDesksIssueFloating.vue'

import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { helpDesksMixins } from './mixins/helpDesksMixins'

import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererDuration from './cell-renderer/CellRendererDuration.vue'
import CellRendererTicketNumber from './cell-renderer/CellRendererTicketNumber.vue'
import CellRendererAssignedTo from './cell-renderer/CellRendererAssignedTo.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'

import HelpDesksIssueForm from './components/HelpDesksIssueForm.vue'
import NextQueueNumber from './components/NextQueueNumber.vue'


export default {
  components: {
    HelpDesksIssueFloating,

    AgGridSelectPageSize,

    CellRendererActions,
    CellRendererDuration,
    CellRendererTicketNumber,
    CellRendererAssignedTo,
    CellRendererStatus,

    HelpDesksIssueForm,
    NextQueueNumber,
  },
  mixins: [
    helpDesksMixins,
    onResizeMixins,
  ],
  data() {
    return {
      appointedToHost: false,
      isLoading: true,
      isLoadingAppointed: true,
      issuesData: [],
      issuesMeta: {
        status: {},
      },
      previousIssuesMeta: {}, // to keep track if new issue has come in

      action: {
        variant: 'dark',
        text: 'Actions',
        icon: 'info_outline',
      },

      popupIssueForm: false,
      selectedIssueId: null,

      showLeftPanel: true,
      currentLeftMenuFilter: 'open',
      issueFilters: [
        // { title: 'all', icon: 'SendIcon', field: 'status', value: '' },
        { title: 'Open', icon: 'SendIcon', field: 'status', value: 'open' },
        { title: 'Closed', icon: 'SendIcon', field: 'status', value: 'closed' },
        // { title: 'Urgent', icon: 'SendIcon', field: 'status', value: 'urgent' },
      ],

      // AgGrid
      context: null,
      gridApi: null,
      gridOptions: {
        getRowClass: params => {
          if (params.data.status === "open" && params.data.status !== 'closed' && params.data.assigned_to === null && params.data.duration < 3000) {
            return 'bg-light-info font-weight-bolder'
          }

          if (params.data.status === 'urgent' && params.data.status !== 'closed' && params.data.assigned_to === null) {
            return 'bg-light-danger font-weight-bolder'
          }
        },
        suppressHorizontalScroll: true,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          headerName: 'Actions',
          field: 'edit_delete',
          width: 130,
          pinned: true,
          cellRendererFramework: 'CellRendererActions',
        },
        {
          headerName: 'Ticket Number',
          field: 'ticket_number',
          filter: true,
          width: 200,
          pinned: true,
          cellRendererFramework: 'CellRendererTicketNumber',
        },
        // {
        //   headerName: 'Queue Number',
        //   field: 'queue.queue_number',
        //   filter: true,
        //   width: 150,
        // },
        {
          headerName: 'Issue Subject',
          field: 'subject',
          filter: true,
          width: 250,
        },
        {
          headerName: 'Assigned To',
          field: 'assigned_to',
          width: 150,
          cellRendererFramework: 'CellRendererAssignedTo',
        },
        {
          headerName: 'Added On',
          field: 'created_at',
          filter: true,
          width: 150,
          valueFormatter: params => this.$formatDatetime(params.value),
        },
        {
          headerName: 'Ticket Issue Owner',
          field: 'user_id',
          filter: true,
          width: 270,
        },
        // {
        //   headerName: 'Status',
        //   field: 'status',
        //   width: 150,
        //   cellRendererFramework: 'CellRendererStatus',
        // },
        // {
        //   headerName: 'Elapsed Time',
        //   field: 'duration',
        //   width: 150,
        //   valueFormatter: params => this.countDuration(params.value),
        // },
        // {
        //   headerName: 'Closed On',
        //   field: 'closed_at',
        //   filter: true,
        //   width: 150,
        //   valueFormatter: params => this.$formatDatetime(params.value),
        // },
      ],

      // server pagination
      previousFilters: {},
      filters: {},
      sorters: [],
      select_columns: [],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 10,
      },

      exportingCSV: false,

      getIssuesInterval: null,
    }
  },
  watch: {
    popupIssueForm(val, oldVal) {
      if (val === false) {
        // this.selectedIssueId = null
      }
    },

    issuesData() {
      setTimeout(() => {
        this.adjustAgGridSize()
      }, 200)
    },

    // server pagination
    'pagination.currentPage': function (val, oldVal) {
      clearInterval(this.getIssuesInterval)
      this.get_main_data()
        .then(() => {
          this.adjustAgGridSize()
          this.gridApi.setFilterModel(this.filters) // infinite loop

          clearInterval(this.getIssuesInterval)
          this.getIssuesInterval = setInterval(() => {
            this.get_main_data(false) // false is to disable b-overlay's isLoading
              .then(() => {
                this.adjustAgGridSize()
                this.gridApi.setFilterModel(this.filters) // infinite loop
              })

            if (this.previousIssuesMeta.status.all !== this.issuesMeta.status.all) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'New Issue',
                    icon: 'InfoCircleIcon',
                    text: 'New issues was created recently',
                    variant: 'warning',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.previousIssuesMeta = this.issuesMeta
            }
          }, 5000)
        })
    },
    'pagination.perPage': function (val, oldVal) {
      clearInterval(this.getIssuesInterval)
      this.get_main_data()
        .then(() => {
          clearInterval(this.getIssuesInterval)
          this.getIssuesInterval = setInterval(() => {
            this.get_main_data(false) // false is to disable b-overlay's isLoading

            if (this.previousIssuesMeta.status.all !== this.issuesMeta.status.all) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'New Issue',
                    icon: 'InfoCircleIcon',
                    text: 'New issues was created recently',
                    variant: 'warning',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.previousIssuesMeta = this.issuesMeta
            }
          }, 5000)
        })
    },

    isMovile(val) {
      this.adjustAgGridSize()
    },
    isTablet(val) {
      this.adjustAgGridSize()
    },
  },
  created() {
    this.get_appointed_to_host_status()

    window.addEventListener('resize', this.adjustAgGridSize)

    this.context = { componentParent: this };
  },
  mounted() {
    // if (this.$store.state.auth.ActiveUser.email_address.includes('@headhuntershq.com.sg')) {
    const customColumn = {
      headerName: 'Source',
      field: 'official_name',
      filter: true,
      width: 200,
    }
    this.columnDefs.splice(3, 0, customColumn)
    // }

    this.filters['status'] = {
      filter: 'open',
      filterType: 'text',
      type: 'contains',
    }
    this.get_main_data()
    this.previousIssuesMeta = this.issuesMeta

    this.gridApi = this.gridOptions.api

    this.getIssuesInterval = setInterval(() => {
      this.get_main_data(false) // false is to disable b-overlay's isLoading

      if (this.previousIssuesMeta.status.all !== this.issuesMeta.status.all) {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'New Issue',
              icon: 'InfoCircleIcon',
              text: 'New issues was created recently',
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )


        this.previousIssuesMeta = this.issuesMeta
      }
    }, 5000)

    if (this.filters['status'].filter === 'open') {
      // console.log('ok')
    }
  },
  destroyed() {
    clearInterval(this.getIssuesInterval)
    window.removeEventListener('resize', this.adjustAgGridSize)
  },
  methods: {
    get_appointed_to_host_status() {
      return new Promise((resolve, reject) => {
        this.isLoadingAppointed = true

        this.$http
          .get(`/api/help-desks/current-user/appointed-to-host`)
          .then(response => {
            this.appointedToHost = response.data.output.appointed_status

            this.isLoadingAppointed = false

            resolve()
          })
          .catch(() => {
            this.isLoadingAppointed = false
            reject()
          })
      })
    },
    get_main_data(isLoading = true) {
      return new Promise((resolve, reject) => {
        this.isLoading = isLoading

        // server pagination
        let query = '?exclude_bot=True' // ! exclude all the issues that is being handled by bots

        if (!this.exportingCSV) { // if it is not for csv will append pagination details else will not paginate and will retrieve all data
          query += `${this.$attachQuerySymbol(query)}pagination=${JSON.stringify(this.pagination)}`
        }

        if (this.filters && Object.keys(this.filters).length > 0) {
          query += `${this.$attachQuerySymbol(query)}filters=${JSON.stringify(this.filters)}`
        }

        if (this.sorters && Object.keys(this.sorters).length > 0) {
          query += `${this.$attachQuerySymbol(query)}sorters=${JSON.stringify(this.sorters)}`
        }

        if (this.select_columns && this.select_columns.length > 0) {
          query += `${this.$attachQuerySymbol(query)}select_columns=${JSON.stringify(this.select_columns)}`
        }

        this.$http
          .get(`/api/help-desks/issues${query}`)
          .then(response => {
            if (this.exportingCSV) {
              // this.$downloadCSV(response.data.users, `User Manager - ${this.$formatDatetime(new Date())}.csv`)
              // this.exportingCSV = false
            } else {
              this.pagination = response.data.pagination
              this.issuesMeta = response.data.meta

              if (this.previousIssuesMeta.status.all !== this.issuesMeta.status.all || this.issuesData.length !== response.data.output.length) {
                this.issuesData = response.data.output

                this.previousIssuesMeta = this.issuesMeta
                this.adjustAgGridSize()
              }

              this.setColumnPinnedState('edit_delete', false)
              this.setColumnPinnedState('ticket_number', false)
            }

            this.isLoading = false

            resolve()
          })
          .catch(() => {
            // this.$toast(
            //   {
            //     component: this.$toastContent,
            //     props: {
            //       title: 'Operation Failed',
            //       icon: 'CheckCircleIcon',
            //       text: 'Could not retrieve the ticket list. Please try again later!',
            //       variant: 'danger',
            //     },
            //   },
            //   { timeout: this.$longestTimeout },
            // )

            this.isLoading = false

            reject()
          })
      })
    },

    confirmAppointToHost() {
      this.$bvModal
        .msgBoxConfirm(
          `Let HeadHunters HQ handle all of the company's issue?`,
          {
            title: 'Confirm Appoint to Host',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'Appoint to Host',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.toggleAppointToHost(true)
          }
        })
    },
    confirmUnappointToHost() {
      this.$bvModal
        .msgBoxConfirm(
          `Take back Help Desk control from HeadHunters HQ?`,
          {
            title: 'Confirm Take Back Control',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'Take Control',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.toggleAppointToHost(false)
          }
        })
    },
    toggleAppointToHost(appointed_status) {
      this.$http
        .put(`/api/help-desks/appointed-to-host/${appointed_status}`)
        .then(response => {
          this.appointedToHost = response.data.output.appointed_status
        })
        .catch(() => {})
    },

    getFilteredIssue(filter) {
      clearInterval(this.getIssuesInterval)
      this.currentLeftMenuFilter = filter.value !== '' ? filter.value : 'all'

      this.filters[filter.field] = {
        filter: filter.value,
        filterType: 'text',
        type: 'contains',
      }

      if (filter.value.toLowerCase() === 'closed') {
        this.columnDefs.push(
          // {
          //   headerName: 'Elapsed Time',
          //   field: 'duration',
          //   width: 150,
          //   valueFormatter: params => this.countDuration(params.value),
          // },
          {
            headerName: 'Closed On',
            field: 'closed_at',
            filter: true,
            width: 150,
            valueFormatter: params => this.$formatDatetime(params.value),
          },
        )
      }

      this.getDataAndResetInterval()
    },

    getDataAndResetInterval() {
      clearInterval(this.getIssuesInterval)
      this.get_main_data()
        .then(() => {
          clearInterval(this.getIssuesInterval)

          this.getIssuesInterval = setInterval(() => {
            this.get_main_data(false) // false is to disable b-overlay's isLoading

            if (this.previousIssuesMeta.status.all !== this.issuesMeta.status.all) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'New Issue',
                    icon: 'InfoCircleIcon',
                    text: 'New issues was created recently',
                    variant: 'warning',
                  },
                },
                { timeout: this.$longestTimeout },
              )

              this.previousIssuesMeta = this.issuesMeta
            }
          }, 5000)
        })
    },

    // ag-grid
    onCellChange(event) {},
    onSortChanged(event) {
      this.sorters = this.gridApi.getSortModel()

      this.get_main_data()
    },
    onFilterChanged(event) {
      this.filters = this.gridApi.getFilterModel()

      setTimeout(() => {
        if (JSON.stringify(this.filters) !== JSON.stringify(this.previousFilters)) {
          this.get_main_data()
            .then(() => {
              this.adjustAgGridSize()

              this.previousFilters = this.filters

              setTimeout(() => {
                this.gridApi.setFilterModel(this.filters) // infinite loop
              }, 100)
            })
        }
      }, 1000)
    },
    onGridReady(event) {
      this.gridApi = this.gridOptions.api

      this.adjustAgGridSize()
    },
    adjustAgGridSize() {
      this.gridOptions.api.setDomLayout('autoHeight')
      this.$refs.agGridTable.$el.style.height = ''

      const gridVh = this.$getGridVh()
      const gridHeight = this.$refs.agGridTable.$el.clientHeight
      if (gridHeight > this.$vh(gridVh)) {
        this.gridOptions.api.setDomLayout('normal')
        this.$refs.agGridTable.$el.style.height = `${gridVh}vh`
      }

      // this.gridApi.sizeColumnsToFit()
    },

    exportToCSV() {},

    changeAction(text) {
      this.action.text = text
      switch (text) {
        case 'Add Ticket':
          this.action.variant = 'success'
          this.action.icon = 'add'
          break
        case 'Appoint to Host':
          this.action.variant = 'warning'
          this.action.icon = 'add'
          break
        // case 'Delete User':
        //   this.action_view = 'danger'
        //   this.action_icon = 'delete'
        //   break
        case 'Actions':
          this.action.variant = 'dark'
          this.action.icon = 'info_outline'
          break
        default:
          this.action.variant = 'dark'
          this.action.icon = 'info_outline'
      }

      this.performAction()
    },
    performAction() {
      if (this.action.text === 'Add Ticket') {
        this.popupIssueForm = true
      }
      if (this.action.text === 'Appoint to Host') {
        this.confirmAppointToHost()
      }
    },

    assignedToMe(id) {
      const routeData = this.$router.resolve({ name: 'help-desks-issues-view', params: { id }})
      window.open(routeData.href, '_blank')
    },

    closeHelpDesksIssueForm() {
      this.popupIssueForm = false

      this.get_main_data()
    },

    editIssue(id) { // need to pass from ag-grid to here
      this.selectedIssueId = id
      this.popupIssueForm = true
    },

    setColumnPinnedState(field, state = null) {
      this.columnDefs.forEach((colDef, index) => {
        if (colDef.field === field) {
          colDef.pinned = state
        }
      })

      this.gridApi.setColumnDefs(this.columnDefs)
    },
  },
}
</script>

<style lang="scss" scoped>

.left-menu > .card-body {
  padding: 0 !important;
}

.right-pane > .card-body {
  padding: 0;
}

</style>
